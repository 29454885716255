import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { useCallback, useEffect, useState } from 'react';
import { Button, Space, Spinner, Size } from '@hse-design/react';
import { Playground, Props } from 'docz';
import { Link, P, ComponentLinks, FigmaEmbed } from '../common';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "spinner"
    }}>{`Spinner`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`import { Spinner } from '@hse-design/react';
`}</code></pre>
    <ComponentLinks figma={'https://www.figma.com/file/cBHD57QcCt9WDT4e7e2B3w/hse_general_library?node-id=8796%3A0'} storybook={'/?path=/story/spinner-spinner--playground'} vueStorybook={'/?path=/story/spinner-spinner--playground'} name={'Spinner'} mdxType="ComponentLinks" />
    <h2 {...{
      "id": "описание"
    }}>{`Описание`}</h2>
    <p>{`Spinner — компонент для отображения состояния загрузки.`}</p>
    <p>{`Значение прогресса задается пропом `}<inlineCode parentName="p">{`value`}</inlineCode>{`. `}<inlineCode parentName="p">{`value`}</inlineCode>{` – число от `}<inlineCode parentName="p">{`0`}</inlineCode>{` до `}<inlineCode parentName="p">{`100`}</inlineCode>{`. Любое число меньше `}<inlineCode parentName="p">{`0`}</inlineCode>{` считается равным `}<inlineCode parentName="p">{`0`}</inlineCode>{`, любое число больше `}<inlineCode parentName="p">{`100`}</inlineCode>{` - равным `}<inlineCode parentName="p">{`100`}</inlineCode>{`.`}</p>
    <p>{`Если прогресс неизвестен, используйте состояние `}<inlineCode parentName="p">{`indeterminate`}</inlineCode>{`. В данном состоянии показывается бесконечная загрузка.`}</p>
    <p>{`У компонента 5 размеров: `}<inlineCode parentName="p">{`small`}</inlineCode>{`, `}<inlineCode parentName="p">{`medium`}</inlineCode>{`, `}<inlineCode parentName="p">{`large`}</inlineCode>{`, `}<inlineCode parentName="p">{`XL`}</inlineCode>{`, `}<inlineCode parentName="p">{`XXL`}</inlineCode>{`. По умолчанию используется `}<inlineCode parentName="p">{`medium`}</inlineCode>{`. Размер задается пропом `}<inlineCode parentName="p">{`size`}</inlineCode>{`.`}</p>
    <p><inlineCode parentName="p">{`Spinner`}</inlineCode>{` можно сделать отменяемым, добавив проп `}<inlineCode parentName="p">{`cancelable`}</inlineCode>{`. Используйте `}<inlineCode parentName="p">{`cancelable`}</inlineCode>{` вариант только с размерами `}<inlineCode parentName="p">{`XL`}</inlineCode>{` и `}<inlineCode parentName="p">{`XXL`}</inlineCode>{`.`}</p>
    <p>{`Для отображения окончания загрузки или успешности операции используйте проп `}<inlineCode parentName="p">{`done`}</inlineCode>{`.`}</p>
    <p>{`Для добавления лейбла и описания используйте компонент `}<a parentName="p" {...{
        "href": "/src-components-labeled-spinner"
      }}>{`LabeledSpinner`}</a></p>
    <Playground __position={1} __code={'<div style={{ display: \'flex\', alignItems: \'center\' }}>\n  <Spinner value={65} size={Spinner.Size.large} />\n  <Space size={Size.small} horizontal />\n  <Spinner indeterminate size={Spinner.Size.XL} />\n  <Space size={Size.small} horizontal />\n  <Spinner done size={Spinner.Size.XXL} />\n</div>'} __scope={{
      props,
      DefaultLayout,
      useCallback,
      useEffect,
      useState,
      Button,
      Space,
      Spinner,
      Size,
      Playground,
      Props,
      Link,
      P,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div style={{
        display: 'flex',
        alignItems: 'center'
      }}>
    <Spinner value={65} size={Spinner.Size.large} mdxType="Spinner" />
    <Space size={Size.small} horizontal mdxType="Space" />
    <Spinner indeterminate size={Spinner.Size.XL} mdxType="Spinner" />
    <Space size={Size.small} horizontal mdxType="Space" />
    <Spinner done size={Spinner.Size.XXL} mdxType="Spinner" />
  </div>
    </Playground>
    <h2 {...{
      "id": "анатомия"
    }}>{`Анатомия`}</h2>
    <FigmaEmbed node={'?node-id=10087:36923'} mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "использование"
    }}>{`Использование`}</h2>
    <FigmaEmbed node={'?node-id=10087:37002'} mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "примеры"
    }}>{`Примеры`}</h2>
    <h3 {...{
      "id": "размер"
    }}>{`Размер`}</h3>
    <p>{`У компонента 5 размеров: `}<inlineCode parentName="p">{`small`}</inlineCode>{`, `}<inlineCode parentName="p">{`medium`}</inlineCode>{`, `}<inlineCode parentName="p">{`large`}</inlineCode>{`, `}<inlineCode parentName="p">{`XL`}</inlineCode>{`, `}<inlineCode parentName="p">{`XXL`}</inlineCode>{`. По умолчанию используется `}<inlineCode parentName="p">{`medium`}</inlineCode>{`. Размер задается пропом `}<inlineCode parentName="p">{`size`}</inlineCode>{`:`}</p>
    <Playground __position={4} __code={'<div style={{ display: \'flex\', alignItems: \'center\' }}>\n  <Spinner value={65} size={Spinner.Size.small} />\n  <Space size={Size.small} horizontal />\n  <Spinner value={65} size={Spinner.Size.medium} />\n  <Space size={Size.small} horizontal />\n  <Spinner value={65} size={Spinner.Size.large} />\n  <Space size={Size.small} horizontal />\n  <Spinner value={65} size={Spinner.Size.XL} />\n  <Space size={Size.small} horizontal />\n  <Spinner value={65} size={Spinner.Size.XXL} />\n</div>'} __scope={{
      props,
      DefaultLayout,
      useCallback,
      useEffect,
      useState,
      Button,
      Space,
      Spinner,
      Size,
      Playground,
      Props,
      Link,
      P,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div style={{
        display: 'flex',
        alignItems: 'center'
      }}>
    <Spinner value={65} size={Spinner.Size.small} mdxType="Spinner" />
    <Space size={Size.small} horizontal mdxType="Space" />
    <Spinner value={65} size={Spinner.Size.medium} mdxType="Spinner" />
    <Space size={Size.small} horizontal mdxType="Space" />
    <Spinner value={65} size={Spinner.Size.large} mdxType="Spinner" />
    <Space size={Size.small} horizontal mdxType="Space" />
    <Spinner value={65} size={Spinner.Size.XL} mdxType="Spinner" />
    <Space size={Size.small} horizontal mdxType="Space" />
    <Spinner value={65} size={Spinner.Size.XXL} mdxType="Spinner" />
  </div>
    </Playground>
    <h3 {...{
      "id": "прогресс-загрузки"
    }}>{`Прогресс загрузки`}</h3>
    <p>{`Значение прогресса задается пропом `}<inlineCode parentName="p">{`value`}</inlineCode>{`. `}<inlineCode parentName="p">{`value`}</inlineCode>{` – число от `}<inlineCode parentName="p">{`0`}</inlineCode>{` до `}<inlineCode parentName="p">{`100`}</inlineCode>{`. Любое число меньше `}<inlineCode parentName="p">{`0`}</inlineCode>{` считается равным `}<inlineCode parentName="p">{`0`}</inlineCode>{`, любое число больше `}<inlineCode parentName="p">{`100`}</inlineCode>{` - равным `}<inlineCode parentName="p">{`100`}</inlineCode></p>
    <Playground __position={5} __code={'<div style={{ display: \'flex\', alignItems: \'center\' }}>\n  <Spinner size={Spinner.Size.large} value={0} />\n  <Space size={Size.small} horizontal />\n  <Spinner size={Spinner.Size.large} value={33} />\n  <Space size={Size.small} horizontal />\n  <Spinner size={Spinner.Size.large} value={50} />\n  <Space size={Size.small} horizontal />\n  <Spinner size={Spinner.Size.large} value={75} />\n</div>'} __scope={{
      props,
      DefaultLayout,
      useCallback,
      useEffect,
      useState,
      Button,
      Space,
      Spinner,
      Size,
      Playground,
      Props,
      Link,
      P,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div style={{
        display: 'flex',
        alignItems: 'center'
      }}>
    <Spinner size={Spinner.Size.large} value={0} mdxType="Spinner" />
    <Space size={Size.small} horizontal mdxType="Space" />
    <Spinner size={Spinner.Size.large} value={33} mdxType="Spinner" />
    <Space size={Size.small} horizontal mdxType="Space" />
    <Spinner size={Spinner.Size.large} value={50} mdxType="Spinner" />
    <Space size={Size.small} horizontal mdxType="Space" />
    <Spinner size={Spinner.Size.large} value={75} mdxType="Spinner" />
  </div>
    </Playground>
    <h3 {...{
      "id": "отменяемый-вариант"
    }}>{`Отменяемый вариант`}</h3>
    <p><inlineCode parentName="p">{`Spinner`}</inlineCode>{` можно сделать отменяемым, добавив проп `}<inlineCode parentName="p">{`cancelable`}</inlineCode>{`. Используйте `}<inlineCode parentName="p">{`cancelable`}</inlineCode>{` вариант только с размерами `}<inlineCode parentName="p">{`XL`}</inlineCode>{` и `}<inlineCode parentName="p">{`XXL`}</inlineCode>{`.`}</p>
    <p>{`Для обработки клика по крестику используйте проп `}<inlineCode parentName="p">{`onCancel`}</inlineCode></p>
    <Playground __position={6} __code={'<div style={{ display: \'flex\', alignItems: \'center\' }}>\n  <Spinner\n    value={65}\n    size=\"XL\"\n    cancelable\n    onCancel={() => console.log(\'Вы отменили загрузку\')}\n  />\n  <Space size={Size.small} horizontal />\n  <Spinner\n    value={65}\n    size=\"XXL\"\n    cancelable\n    onCancel={() => console.log(\'Вы отменили загрузку\')}\n  />\n</div>'} __scope={{
      props,
      DefaultLayout,
      useCallback,
      useEffect,
      useState,
      Button,
      Space,
      Spinner,
      Size,
      Playground,
      Props,
      Link,
      P,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div style={{
        display: 'flex',
        alignItems: 'center'
      }}>
    <Spinner value={65} size="XL" cancelable onCancel={() => console.log("Вы отменили загрузку")} mdxType="Spinner" />
    <Space size={Size.small} horizontal mdxType="Space" />
    <Spinner value={65} size="XXL" cancelable onCancel={() => console.log("Вы отменили загрузку")} mdxType="Spinner" />
  </div>
    </Playground>
    <h3 {...{
      "id": "бесконечная-загрузка"
    }}>{`Бесконечная загрузка`}</h3>
    <p>{`Если прогресс неизвестен, используйте состояние `}<inlineCode parentName="p">{`indeterminate`}</inlineCode>{`. В данном состоянии показывается бесконечная загрузка.`}</p>
    <p>{`Удобно использовать для отображения загрузки страницы`}</p>
    <Playground __position={7} __code={'<div style={{ display: \'flex\', alignItems: \'center\' }}>\n  <Spinner value={65} size=\"small\" indeterminate />\n  <Space size={Size.small} horizontal />\n  <Spinner value={65} size=\"medium\" indeterminate />\n  <Space size={Size.small} horizontal />\n  <Spinner value={65} size=\"large\" indeterminate />\n  <Space size={Size.small} horizontal />\n  <Spinner value={65} size=\"XL\" indeterminate />\n  <Space size={Size.small} horizontal />\n  <Spinner value={65} size=\"XXL\" indeterminate />\n</div>'} __scope={{
      props,
      DefaultLayout,
      useCallback,
      useEffect,
      useState,
      Button,
      Space,
      Spinner,
      Size,
      Playground,
      Props,
      Link,
      P,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div style={{
        display: 'flex',
        alignItems: 'center'
      }}>
    <Spinner value={65} size="small" indeterminate mdxType="Spinner" />
    <Space size={Size.small} horizontal mdxType="Space" />
    <Spinner value={65} size="medium" indeterminate mdxType="Spinner" />
    <Space size={Size.small} horizontal mdxType="Space" />
    <Spinner value={65} size="large" indeterminate mdxType="Spinner" />
    <Space size={Size.small} horizontal mdxType="Space" />
    <Spinner value={65} size="XL" indeterminate mdxType="Spinner" />
    <Space size={Size.small} horizontal mdxType="Space" />
    <Spinner value={65} size="XXL" indeterminate mdxType="Spinner" />
  </div>
    </Playground>
    <h3 {...{
      "id": "загруженное-состояние"
    }}>{`Загруженное состояние`}</h3>
    <p>{`Для отображения окончания загрузки или успешности операции используйте проп `}<inlineCode parentName="p">{`done`}</inlineCode></p>
    <Playground __position={8} __code={'() => {\n  const [done, setDone] = useState(true)\n  const value = 0\n  const onRetryClick = useCallback(() => {\n    setDone(false)\n    setTimeout(() => {\n      setDone(true)\n    }, 100)\n  }, [])\n  return (\n    <>\n      <Button variant=\"primary\" size=\"medium\" onClick={onRetryClick}>\n        Retry\n      </Button>\n      <Space size=\"small_3x\" horizontal vertical />\n      <div style={{ display: \'flex\', alignItems: \'center\' }}>\n        <Spinner value={value} size=\"small\" done={done} />\n        <Space size=\"small_3x\" horizontal />\n        <Spinner value={value} size=\"medium\" done={done} />\n        <Space size=\"small_3x\" horizontal />\n        <Spinner value={value} size=\"large\" done={done} />\n        <Space size=\"small_3x\" horizontal />\n        <Spinner value={value} size=\"XL\" done={done} />\n        <Space size=\"small_3x\" horizontal />\n        <Spinner value={value} size=\"XXL\" done={done} />\n      </div>\n    </>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useCallback,
      useEffect,
      useState,
      Button,
      Space,
      Spinner,
      Size,
      Playground,
      Props,
      Link,
      P,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [done, setDone] = useState(true);
        const value = 0;
        const onRetryClick = useCallback(() => {
          setDone(false);
          setTimeout(() => {
            setDone(true);
          }, 100);
        }, []);
        return <>
        <Button variant="primary" size="medium" onClick={onRetryClick} mdxType="Button">Retry</Button>
        <Space size="small_3x" horizontal vertical mdxType="Space" />
        <div style={{
            display: 'flex',
            alignItems: 'center'
          }}>
          <Spinner value={value} size="small" done={done} mdxType="Spinner" />
          <Space size="small_3x" horizontal mdxType="Space" />
          <Spinner value={value} size="medium" done={done} mdxType="Spinner" />
          <Space size="small_3x" horizontal mdxType="Space" />
          <Spinner value={value} size="large" done={done} mdxType="Spinner" />
          <Space size="small_3x" horizontal mdxType="Space" />
          <Spinner value={value} size="XL" done={done} mdxType="Spinner" />
          <Space size="small_3x" horizontal mdxType="Space" />
          <Spinner value={value} size="XXL" done={done} mdxType="Spinner" />
        </div>
      </>;
      }}
    </Playground>
    <h2 {...{
      "id": "пропы"
    }}>{`Пропы`}</h2>
    <Props of={Spinner} mdxType="Props" />
    <p>{`Все остальные свойства пробрасываются на корневой элемент `}<inlineCode parentName="p">{`div`}</inlineCode>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      